import capitalize from "lodash/capitalize.js";
import { nanoid } from "nanoid";
import dayjs from "dayjs";
import { NonRetriableError } from "inngest";
import { get } from "psl";
export const removeHTMLTags = (html) => {
    return html.replace(/<[^>]*>?/gm, "");
};
function replacePlaceholders(template, replaceMap) {
    const regex = /\[([a-zA-Z0-9_]+(\.[a-zA-Z0-9_]+|\[[0-9]+\])+)\]/g;
    return template.replace(regex, (match) => {
        return replaceMap[match] ?? match; // if no match, return original string
    });
}
function replacePlaceholdersForCells(template, lead) {
    const regex = /\[trigiq_cells@(\d+)\]/g;
    return template.replace(regex, (match, id) => {
        const valueFound = lead.trigiq_cells?.find((d) => `${d?.trigiq_column_id}` === `${id}`);
        return valueFound ? valueFound.value : match;
    });
}
export const replacePlaceholersInIntegrationContactTemplate = (template, lead) => {
    const newString = template;
    const replaceMap = {
        "[prospect.location_name]": lead?.location_name,
        "[prospect.first_name]": lead?.first_name,
        "[prospect.last_name]": lead?.last_name,
        "[prospect.full_name]": `${lead?.first_name} ${lead?.last_name}`,
        "[prospect.linkedin_url]": lead?.linkedin_url,
        "[prospect.job_title]": lead?.job_title,
        "[prospect.email]": lead?.email,
        "[prospect.recommended_personal_email]": lead?.recommended_personal_email,
        "[prospect.work_email]": lead?.work_email,
        "[prospect.mobile_phone]": lead?.mobile_phone,
        "[prospect.birth_date]": lead?.birth_date,
        "[prospect.job_start_date]": lead?.job_start_date,
        "[prospect.job_summary]": lead?.job_summary,
        "[prospect.location]": lead?.location_name,
        "[prospect.github_url]": lead?.github_url,
        "[prospect.github_username]": lead?.github_username,
        "[prospect.facebook_url]": lead?.facebook_url,
        "[prospect.facebook_username]": lead?.facebook_username,
        "[prospect.facebook_id]": lead?.facebook_id,
        "[prospect.linkedin_id]": lead?.linkedin_id,
        "[prospect.linkedin_username]": lead?.linkedin_username,
        "[prospect.twitter_url]": lead?.twitter_url,
        "[prospect.twitter_username]": lead?.twitter_username,
        // "[hiring_job.title]": lead.company?.jobtitle,
        // "[hiring_job.description]": removeHTMLTags(
        //   lead.company?.jobdescription ?? ""
        // ),
        // "[hiring_job.location]": lead.hiring_job?.location,
        // "[hiring_job.apply_url]": lead.hiring_job?.apply_url,
        "[company.size]": lead.company?.size,
        "[company.name]": lead.company?.name,
        "[company.industry]": lead.company?.industry,
        "[company.location]": lead.company?.location,
        "[company.revenue]": lead.company?.revenue ?? "Revenue - Unknown",
        "[company.events]": lead.company?.events?.map((e) => e?.categories?.join(", "))?.join(", ") ?? "Events - Unknown",
        "[company.employees_count]": lead.company?.employees_count ?? "Employees - Unknown",
        "[company.monthly_visitors]": lead.company?.monthly_visitors ?? "Monthly Visitors - Unknown",
        "[company.technologies]": lead.company?.technologies?.join(", ") ?? "Technologies - Unknown",
        "[company.total_funding]": String(lead.company?.total_funding ?? "Total Funding - Unknown"),
        "[company.domain]": String(lead.company?.domain),
    };
    // Replace strings
    let finalString = replacePlaceholders(newString, replaceMap);
    // Replace cell variables
    finalString = replacePlaceholdersForCells(finalString, lead);
    return finalString;
};
export const replacePlaceholersInTemplate = (template, lead) => {
    const newString = template;
    const replaceMap = {
        "[prospect.location_name]": lead.prospect?.location_name,
        "[prospect.first_name]": lead.prospect?.first_name,
        "[prospect.last_name]": lead.prospect?.last_name,
        "[prospect.full_name]": lead.prospect?.full_name,
        "[prospect.linkedin_url]": lead.prospect?.linkedin_url,
        "[prospect.job_title]": lead.prospect?.job_title,
        "[prospect.enriched_emails[0].email]": lead.prospect?.enriched_emails?.[0]?.email,
        "[prospect.enriched_profile.previous_company_name]": lead.prospect?.enriched_profile?.previous_company_name,
        "[prospect.enriched_profile.recommended_personal_email]": lead.prospect?.enriched_profile?.recommended_personal_email,
        "[prospect.enriched_profile.work_email]": lead.prospect?.enriched_profile?.work_email,
        "[prospect.enriched_profile.mobile_phone]": lead.prospect?.enriched_profile?.mobile_phone,
        "[prospect.enriched_profile.birth_date]": lead.prospect?.enriched_profile?.birth_date,
        "[prospect.enriched_profile.job_start_date]": lead.prospect?.enriched_profile?.job_start_date,
        "[prospect.enriched_profile.job_summary]": lead.prospect?.enriched_profile?.job_summary,
        "[prospect.location]": lead.prospect?.location_name,
        "[prospect.enriched_profile.github_url]": lead.prospect?.enriched_profile?.github_url,
        "[prospect.enriched_profile.github_username]": lead.prospect?.enriched_profile?.github_username,
        "[prospect.enriched_profile.facebook_url]": lead.prospect?.enriched_profile?.facebook_url,
        "[prospect.enriched_profile.facebook_username]": lead.prospect?.enriched_profile?.facebook_username,
        "[prospect.enriched_profile.facebook_id]": lead.prospect?.enriched_profile?.facebook_id,
        "[prospect.enriched_profile.linkedin_id]": lead.prospect?.enriched_profile?.linkedin_id,
        "[prospect.enriched_profile.linkedin_username]": lead.prospect?.enriched_profile?.linkedin_username,
        "[prospect.enriched_profile.twitter_url]": lead.prospect?.enriched_profile?.twitter_url,
        "[prospect.enriched_profile.twitter_username]": lead.prospect?.enriched_profile?.twitter_username,
        "[prospect.enriched_profile.birth_year]": lead.prospect?.enriched_profile?.birth_year,
        "[prospect.prospect_organisation_tag]": lead.prospect?.prospect_organisation_tag?.map((t) => t?.title).join(", "),
        "[linkedin_engagement.comments]": lead.prospect?.linkedin_engagement?.filter((e) => e?.type === "COMMENT").length.toString() + " comments",
        "[linkedin_engagement.likes]": lead.prospect?.linkedin_engagement?.filter((e) => e?.type === "LIKE").length.toString() + " likes",
        "[linkedin_engagement.post]": lead?.prospect?.linkedin_engagement?.find((e) => e?.author_id === lead?.prospect_id)?.linkedin_post?.text ?? "",
        "[hiring_job.title]": lead.hiring_job?.title,
        "[hiring_job.description]": removeHTMLTags(lead.hiring_job?.description ?? ""),
        "[job.location]": lead.job?.location,
        "[job.apply_url]": lead.job?.apply_url,
        "[hiring_job.location]": lead.hiring_job?.location,
        "[hiring_job.apply_url]": lead.hiring_job?.apply_url,
        "[company.size]": lead.company?.size,
        "[company.name]": lead.company?.name,
        "[company.industry]": lead.company?.industry,
        "[company.location]": lead.company?.location,
        "[hiring_company.size]": lead.hiring_company?.size,
        "[hiring_company.name]": lead.hiring_company?.name,
        "[hiring_company.industry]": lead.hiring_company?.industry,
        "[hiring_company.location]": lead.hiring_company?.location,
        "[company.revenue]": lead.company?.revenue ?? "Revenue - Unknown",
        "[hiring_company.revenue]": lead.hiring_company?.revenue ?? "Revenue - Unknown",
        "[company.events]": lead.company?.events?.map((e) => e?.categories?.join(", "))?.join(", ") ?? "Events - Unknown",
        "[hiring_company.events]": lead.hiring_company?.events?.map((e) => e?.categories?.join(", "))?.join(", ") ?? "Events - Unknown",
        "[company.employees_count]": lead.company?.employees_count ?? "Employees - Unknown",
        "[company.monthly_visitors]": lead.company?.monthly_visitors ?? "Monthly Visitors - Unknown",
        "[hiring_company.employees_count]": lead.hiring_company?.employees_count ?? "Employees - Unknown",
        "[hiring_company.monthly_visitors]": lead.hiring_company?.monthly_visitors ?? "Monthly Visitors - Unknown",
        "[company.technologies]": lead.company?.technologies?.join(", ") ?? "Technologies - Unknown",
        "[hiring_company.technologies]": lead.hiring_company?.technologies?.join(", ") ?? "Technologies - Unknown",
        "[company.total_funding]": String(lead.company?.total_funding ?? "Total Funding - Unknown"),
        "[hiring_company.total_funding]": String(lead.hiring_company?.total_funding ?? "Total Funding - Unknown"),
        "[company.domain]": String(lead.company?.domain),
        "[hiring_company.domain]": String(lead.hiring_company?.domain),
    };
    // Replace strings
    let finalString = replacePlaceholders(newString, replaceMap);
    // Replace cell variables
    finalString = replacePlaceholdersForCells(finalString, lead);
    return finalString;
};
export const processStream = async (res) => {
    const reader = res.body?.getReader();
    const decoder = new TextDecoder("utf-8");
    let result = "";
    let response = await reader?.read();
    while (response && !response.done) {
        const value = response.value;
        result += decoder.decode(value);
        response = await reader?.read();
    }
    console.log("Stream complete", result);
};
export const mapToTriggerFormat = (input) => (input?.length ?? 0) > 0 ? input?.reduce((acc, cur) => `${acc}, ${cur}`) : "";
// Create an API error that can be thrown with a message and a status code
export class APIError extends Error {
    status;
    constructor(message, status) {
        super(message);
        this.status = status;
    }
}
export class PaymentRequiredError extends NonRetriableError {
    constructor(message) {
        super(message, {
            cause: "PaymentRequiredError - Not enough credits",
        });
        this.name = "PaymentRequiredError";
    }
}
export class PaymentError extends NonRetriableError {
    constructor(message) {
        super(message, {
            cause: "PaymentError - Error with Stripe",
        });
        this.name = "PaymentError";
    }
}
export const validateIndustry = (industry, triggerIndustries, triggerIndustryExclude) => {
    if (triggerIndustries.length > 0) {
        if (!triggerIndustries.includes(industry)) {
            return false;
        }
    }
    if (triggerIndustryExclude.length > 0) {
        if (triggerIndustryExclude.includes(industry)) {
            return false;
        }
    }
    return true;
};
export function getDomain(url) {
    try {
        if (!url) {
            return null;
        }
        // make sure theres atleast 3 parts to the url if not assume its a domain and return it
        const parts = url.split(".");
        if ((parts.length ?? 0) < 3 && (parts.length ?? 0) > 1 && !url.startsWith("http") && !url.startsWith("https")) {
            return url;
        }
        let newUrl = url;
        if (url.startsWith("http") || url.startsWith("https")) {
            newUrl = new URL(url).hostname;
        }
        const result = get(newUrl);
        console.log(`Domain: ${result}`);
        return result;
    }
    catch (e) {
        const err = e;
        console.log(err);
        console.warn(`Invalid URL: ${url}`);
        return null;
    }
}
export const removeProtocol = (url) => {
    const removedProtocol = url.replace(/^https?:\/\//, "");
    const removedSubdomain = removedProtocol.replace(/www\./, "");
    // remove trailing slash
    return removedSubdomain.replace(/\/$/, "");
};
export const createHaskeyFromStringArray = (arr) => {
    return arr.reduce((acc, cur) => {
        // take the vanity name after /in/ and use that as the key
        const key = cur.split("/in/")[1];
        return `${acc}_${key}`;
    }, "");
};
export const sanitiseLinkedinUrl = (url) => {
    // make sure no query params are present or trailing slash
    return url.split("?")[0]?.replace(/\/$/, "");
};
export const mapSizeNumberToString = (size) => {
    const sizeMappings = [
        [10, "1-10"],
        [50, "10-50"],
        [200, "50-200"],
        [500, "200-500"],
        [1000, "500-1k"],
        [5000, "1k-5k"],
        [10000, "5k-10k"],
    ];
    for (const [limit, label] of sizeMappings) {
        if (size <= limit) {
            return label;
        }
    }
    return "over-10k";
};
export const mapB2BCompanyDataToHiringCompany = (company) => {
    return {
        city: company.locations.headquarter.city,
        annual_growth_rate: null,
        company_logo: company.logo ?? `https://logo.clearbit.com/${company.website}`,
        country: company.locations.headquarter?.country ?? "",
        created: new Date(),
        description: company.description ?? "",
        domain: getDomain(company.website),
        employees_count: `${company.staff.total}`,
        industry: company.industries[0] ?? "",
        linkedin_url: company.url,
        location: company.locations.headquarter.country ?? "",
        name: company.name,
        state: company.locations.headquarter?.geographicArea ?? "",
        size: `${company.staff.range.start} - ${company.staff.range.end}`,
    };
};
export const mapLinkedinCompanyDataToHiringCompany = (company) => {
    return {
        city: company.headquarter?.city,
        annual_growth_rate: null,
        company_logo: company.logoResolutionResult ?? `https://logo.clearbit.com/${company.websiteUrl}`,
        country: company.headquarter?.country ?? "",
        created: new Date(),
        description: company.description ?? "",
        domain: getDomain(company.websiteUrl),
        employees_count: `${company.employeeCount}`,
        industry: company.industry,
        linkedin_url: company.url,
        location: company.headquarter?.city ?? "",
        name: company.companyName,
        state: company.headquarter?.geographicArea ?? "",
        size: mapSizeNumberToString(company.employeeCount),
    };
};
export const shortId = () => {
    return nanoid(20);
};
export const addOneMonthStartOfDay = () => dayjs().add(1, "month").startOf("day").toDate();
const generateNameFromFullName = (fullName) => {
    const firstName = fullName?.split(" ")[0];
    const lastName = fullName?.split(" ")[1];
    return {
        firstName: capitalize(firstName),
        lastName: capitalize(lastName),
        fullName: `${capitalize(firstName)} ${capitalize(lastName)}`,
    };
};
export const mapPDLPersonToHiringPerson = (person) => {
    const { firstName, lastName, fullName } = generateNameFromFullName(person.full_name);
    return {
        hiring_prospect: {
            first_name: firstName,
            last_name: lastName,
            full_name: fullName,
            linkedin_url: person.linkedin_url,
            location_name: person.location_name,
            job_title: capitalize(person.job_title ?? ""),
            previous_company_name: getSafePreviousCompanyName(person),
            job_start_date: person.job_start_date ?? "",
            birth_date: person.birth_date ?? "",
            job_summary: person.job_summary ?? "",
            job_title_levels: person.job_title_levels ?? [],
            birth_year: person.birth_year?.toString() ?? "",
            facebook_id: person.facebook_id ?? "",
            facebook_url: person.facebook_url ?? "",
            facebook_username: person.facebook_username ?? "",
            github_url: person.github_url ?? "",
            github_username: person.github_username ?? "",
            linkedin_id: person.linkedin_id ?? "",
            linkedin_username: person.linkedin_username ?? "",
            location_country: person.location_country ?? "",
            location_geo: person.location_geo ?? "",
            location_region: person.location_region ?? "",
            mobile_phone: person.mobile_phone ?? "",
            recommended_personal_email: person.recommended_personal_email ?? "",
            twitter_url: person.twitter_url ?? "",
            twitter_username: person.twitter_username ?? "",
            work_email: person.work_email ?? "",
            job_company_name: person.job_company_name ?? "",
            job_company_website: person.job_company_website ?? "",
            job_title_role: person.job_title_role ?? "",
            job_title_sub_role: person.job_title_sub_role ?? "",
        },
        hiring_company: {
            company_logo: `https://logo.clearbit.com/${person.job_company_website}`,
            country: person.job_company_location_country ?? "",
            description: "",
            total_funding: person.job_company_total_funding_raised ?? 0,
            revenue: person.job_company_inferred_revenue ?? "",
            industry: person.industry ?? person.job_company_industry ?? "",
            city: person.job_company_location_name ?? "",
            domain: person.job_company_website,
            name: person.job_company_name,
            size: person.job_company_size ?? "",
            location: person.job_company_location_name ?? "",
            employees_count: person.job_company_size ?? "",
            linkedin_url: person.job_company_linkedin_url ?? "",
            annual_growth_rate: person.job_company_12mo_employee_growth_rate ?? 0,
        },
        hiring_email_template: {
            body: "",
        },
    };
};
export const isCoreSignalJob = (job) => "company_name" in job;
export const isPredictLeadsJob = (job) => "attributes" in job;
const isJobsApiJob = (job) => "published" in job;
const isTheirStackJob = (job) => "has_blurred_data" in job;
const getScraperSource = (url) => {
    // extract the domain from the url
    return getDomain(url);
};
export const getSafePreviousCompanyName = (person) => {
    // if the person has a previous company there should be 2 or more elements in the array
    // always take the 2nd element.. if it doesn't exist, return an empty string
    return person.experience?.length >= 2 ? person.experience[1]?.company?.name : "";
};
export const mapToJobModel = (job) => {
    if (!job) {
        return;
    }
    if (isTheirStackJob(job)) {
        return {
            employer_industry: job.company_object.industry,
            employer_name: job.company_object.name,
            employer_website: job.company_object.domain ?? job.company_domain,
            job_apply_url: job.url,
            job_city: job.location,
            job_country: job.country,
            job_description: job.description,
            job_title: job.job_title,
            job_posted_date: job.date_posted,
            job_state: job.location,
            employer_linkedin_url: job.company_object.linkedin_url,
            job_location: job.location,
            linked_in_id: job.company_object.linkedin_id,
            scraper_source: getScraperSource(job.company_object.url_source) ?? "",
            min_annual_salary: job.max_annual_salary,
            min_annual_salary_usd: job.min_annual_salary_usd,
            max_annual_salary: job.max_annual_salary,
            max_annual_salary_usd: job.max_annual_salary_usd,
            avg_annual_salary_usd: job.avg_annual_salary_usd,
            salary_currency: job.salary_currency,
            seniority: job.seniority,
        };
    }
    if (isPredictLeadsJob(job)) {
        return {
            employer_industry: "",
            employer_name: job.company.attributes.company_name,
            employer_website: job.company.attributes.domain,
            job_apply_url: job.attributes.url,
            job_city: job.attributes.additional_data.location_data.city,
            job_country: job.attributes.additional_data.location_data.country,
            job_description: job.attributes.title,
            job_title: job.attributes.title,
            job_posted_date: job.attributes.first_seen_at,
            job_state: "",
            employer_linkedin_url: "",
            job_location: job.attributes.additional_data.location_data.country,
            linked_in_id: "",
        };
    }
    if (isJobsApiJob(job)) {
        return {
            employer_industry: "",
            employer_name: job.company.name,
            employer_website: job.company?.website_url ?? "",
            job_apply_url: job.application_url,
            job_city: job.location,
            job_country: job.countries[0]?.name ?? job.location,
            job_description: job.description,
            job_title: job.title,
            job_posted_date: job.published,
            job_state: job.countries[0]?.region.name ?? job.location,
            employer_linkedin_url: job.company?.linkedin_url ?? "",
            job_location: job.location,
            linked_in_id: "",
        };
    }
    if (isCoreSignalJob(job)) {
        return {
            core_signal_id: job.id,
            employer_industry: job.job_industry_collection[0]?.job_industry_list.industry ?? "",
            employer_name: job.company_name,
            employer_website: job.external_url,
            employer_linkedin_url: job.company_url,
            job_description: job.description,
            job_title: job.title,
            job_apply_url: job.url,
            job_city: job.location,
            job_country: job.country,
            job_posted_date: job.created,
            job_state: job.location,
            applicant_count: job.applicants_count,
            job_location: job.location,
        };
    }
    throw new Error("Job is not a valid type");
};
export const determineWaitTime = (numberOfEvents) => {
    switch (true) {
        // between 1 and 10 events
        case numberOfEvents > 0 && numberOfEvents <= 10:
            return "1m";
        // between 11 and 50 events
        case numberOfEvents > 10 && numberOfEvents <= 50:
            return "2m";
        // between 51 and 100 events
        case numberOfEvents > 50 && numberOfEvents <= 100:
            return "5m";
        // between 101 and 200 events
        case numberOfEvents > 100 && numberOfEvents <= 200:
            return "10m";
        default:
            // 1 minute
            return "30s";
    }
};
export const getJobTitleFromProfile = (profile, companyName) => {
    const experienceToUse = profile?.experiences?.find((exp) => exp.companyLink1);
    if (!experienceToUse) {
        return "";
    }
    if (experienceToUse.title === companyName) {
        // then use the sub component
        const subComponent = experienceToUse.subComponents?.find((c) => c?.title !== companyName);
        if (subComponent) {
            return capitalize(subComponent.title);
        }
    }
    return capitalize(experienceToUse.title);
};
const getJobTitleAndCompanyFromPositionAlt = (positions) => {
    let jobTitle = "";
    let companyName = "";
    let companyUrl = "";
    let industry = "";
    if (positions?.length === 0) {
        return {
            jobTitle,
            companyName,
            companyUrl,
        };
    }
    for (const position of positions) {
        if (position.companyLink1 || position.companyLink2) {
            jobTitle = position.title;
            companyName = position.subtitle;
            companyUrl = position.companyLink1 ?? position.companyLink2;
            industry = "";
        }
        if (jobTitle && companyName && companyUrl) {
            break;
        }
    }
    return {
        jobTitle,
        companyName,
        companyUrl,
        industry,
    };
};
const getJobTitleAndCompanyFromPosition = (positions) => {
    let jobTitle = "";
    let companyName = "";
    let companyUrl = "";
    let industry = "";
    if (positions?.length === 0) {
        return {
            jobTitle,
            companyName,
            companyUrl,
        };
    }
    for (const position of positions) {
        if (position.companyURL) {
            jobTitle = position.title;
            companyName = position.companyName;
            companyUrl = position.companyURL;
            industry = position.companyIndustry;
        }
        if (jobTitle && companyName && companyUrl) {
            break;
        }
    }
    return {
        jobTitle,
        companyName,
        companyUrl,
        industry,
    };
};
const getJobTitleAndCompanyFromPositionB2B = (positions) => {
    let jobTitle = "";
    let companyName = "";
    let companyUrl = "";
    let industry = "";
    if (positions?.length === 0) {
        return {
            jobTitle,
            companyName,
            companyUrl,
        };
    }
    for (const position of positions) {
        if (position.company.url) {
            jobTitle = position.profilePositions[0]?.title ?? "";
            companyName = position.company.name ?? position.profilePositions[0]?.company ?? "";
            companyUrl = position.company.url;
            industry = "";
        }
        if (jobTitle && companyName && companyUrl) {
            break;
        }
    }
    return {
        jobTitle,
        companyName,
        companyUrl,
        industry,
    };
};
export const mapLinkedinProfileToProspectAlt = (profile) => {
    const { companyName, jobTitle, companyUrl, industry } = getJobTitleAndCompanyFromPositionAlt(profile.experiences);
    return {
        gender: "",
        job_title_levels: [],
        job_title_role: "",
        job_title_sub_role: "",
        created: new Date(),
        updated: new Date(),
        avatar: profile.profilePic ?? "",
        first_name: capitalize(profile.firstName) ?? "",
        full_name: `${capitalize(profile.firstName)} ${capitalize(profile.lastName)}` || "",
        last_name: capitalize(profile.lastName) ?? "",
        job_company_name: companyName ?? "",
        job_title: jobTitle ?? "",
        job_company_website: companyUrl ?? "",
        industry: industry ?? "",
        location_name: profile.addressWithCountry ?? "",
        location_country: profile.addressCountryOnly ?? "",
        linkedin_urn: profile.urn ?? "",
        linkedin_url: profile?.publicIdentifier
            ? `linkedin.com/in/${profile.publicIdentifier}`
            : profile.urn
                ? `linkedin.com/in/${profile.urn}`
                : null,
    };
};
export const mapB2BLinkedinProfileToProspect = (profile) => {
    const { companyName, jobTitle, companyUrl, industry } = getJobTitleAndCompanyFromPositionB2B(profile.positionGroups);
    return {
        gender: "",
        job_title_levels: [],
        job_title_role: "",
        job_title_sub_role: "",
        created: new Date(),
        updated: new Date(),
        avatar: profile.picture,
        first_name: capitalize(profile.firstname) ?? "",
        last_name: capitalize(profile.lastname) ?? "",
        full_name: `${capitalize(profile.firstname)} ${capitalize(profile.lastname)}` || "",
        job_company_name: companyName ?? "",
        job_title: jobTitle ?? "",
        job_company_website: companyUrl ?? "",
        industry: industry ?? "",
        location_name: profile.location.country ?? "",
        location_country: profile.location.country ?? "",
        linkedin_url: profile.identifier
            ? `linkedin.com/in/${profile.identifier}`
            : profile.entityUrn
                ? `linkedin.com/in/${profile.entityUrn}`
                : profile.url ?? "",
        linkedin_urn: profile.entityUrn ?? "",
    };
};
export const mapLinkedinProfileToProspect = (profile) => {
    const { companyName, jobTitle, companyUrl, industry } = getJobTitleAndCompanyFromPosition(profile.position);
    return {
        gender: "",
        job_title_levels: [],
        job_title_role: "",
        job_title_sub_role: "",
        linkedin_urn: profile.urn ?? "",
        created: new Date(),
        updated: new Date(),
        avatar: profile.profilePicture ?? "",
        first_name: capitalize(profile.firstName) ?? "",
        full_name: `${capitalize(profile.firstName)} ${capitalize(profile.lastName)}` || "",
        last_name: capitalize(profile.lastName) ?? "",
        job_company_name: companyName ?? "",
        job_title: jobTitle ?? "",
        job_company_website: companyUrl ?? "",
        industry: industry ?? "",
        location_name: profile.geo.full ?? profile.geo.country ?? "",
        location_country: profile.geo.country ?? "",
        linkedin_url: `linkedin.com/in/${profile.username ?? profile.urn}`,
    };
};
export const mapGetProfileByLinkedInUrl = (profile) => {
    const { companyName, jobTitle, companyUrl, industry } = getJobTitleAndCompanyFromPositionAlt(profile.experiences);
    return {
        created: new Date(),
        updated: new Date(),
        first_name: capitalize(profile.firstName) ?? "",
        full_name: `${capitalize(profile.firstName)} ${capitalize(profile.lastName)}` || "",
        last_name: capitalize(profile.lastName) ?? "",
        job_company_name: companyName ?? "",
        job_title: jobTitle ?? "",
        job_company_website: companyUrl ?? "", // this is the company LI url
        industry: industry ?? "",
        location_name: profile.addressWithCountry ?? "",
        location_country: profile.addressCountryOnly ?? "",
        gender: "",
        avatar: profile.profilePic ?? "",
        job_title_levels: [],
        job_title_role: profile?.experiences?.[0]?.title ?? "",
        job_title_sub_role: "",
        linkedin_urn: profile.urn ?? "",
        linkedin_url: profile?.publicIdentifier
            ? `linkedin.com/in/${profile.publicIdentifier}`
            : profile.urn
                ? `linkedin.com/in/${profile.urn}`
                : null,
    };
};
export const mapCompanyResponseToCompany = (company, linkedinUrl) => {
    return {
        annual_growth_rate: null,
        city: company.headquarter.city ?? "",
        company_logo: company.website ? `https://logo.clearbit.com/${company.website}` : company.logos[0]?.url ?? "",
        country: company.headquarter.country ?? "",
        created: new Date(),
        description: company.description ?? "",
        domain: getDomain(company.website) ?? "",
        employees_count: company.staffCount.toString(),
        size: company.staffCountRange ?? "",
        industry: company.industries[0] ?? "",
        total_funding: Number.parseInt(company.fundingData.lastFundingRound.moneyRaised.amount),
        linkedin_url: linkedinUrl,
        name: company.name,
        followed_on_predict_leads: false,
        location: company.headquarter.country ?? "",
        monthly_visitors: null,
        revenue: null,
        state: company.headquarter.geographicArea ?? "",
        technologies: [],
        updated: new Date(),
    };
};
export const mapPersonResponseToProfile = (person) => {
    return {
        linkedin_urn: "",
        avatar: person.avatar ?? "",
        created: new Date(),
        updated: new Date(),
        first_name: capitalize(person.full_name?.split(" ")[0]) ?? "",
        full_name: `${capitalize(person.full_name?.split(" ")[0])} ${capitalize(person.full_name?.split(" ")[1])}` || "",
        last_name: capitalize(person.full_name?.split(" ")[1]) ?? "",
        industry: person.industry ?? "",
        job_company_name: person.job_company_name ?? "",
        location_country: person.location_country ?? "",
        job_title: capitalize(person.job_title ?? "") ?? "",
        job_company_website: person.job_company_website ?? "",
        job_title_levels: person.job_title_levels ?? [],
        job_title_role: person.job_title_role ?? "",
        job_title_sub_role: person.job_title_sub_role ?? "",
        linkedin_url: person.linkedin_url ?? "",
        location_name: person.location_name ?? "",
        gender: "",
    };
};
export const mapPersonResponseToEnrichedProfile = (person) => ({
    birth_date: person.birth_date ?? "",
    birth_year: person.birth_year?.toString() ?? "",
    linkedin_url: person.linkedin_url ?? "",
    facebook_id: person.facebook_id ?? "",
    facebook_url: person.facebook_url ?? "",
    facebook_username: person.facebook_username ?? "",
    first_name: person.first_name ?? "",
    github_url: person.github_url ?? "",
    github_username: person.github_username ?? "",
    job_start_date: person.job_start_date ?? "",
    job_summary: person.job_summary ?? "",
    last_name: person.last_name ?? "",
    linkedin_id: person.linkedin_id ?? "",
    linkedin_username: person.linkedin_username ?? "",
    location_country: person.location_country ?? "",
    location_geo: person.location_geo ?? "",
    location_region: person.location_region ?? "",
    mobile_phone: person.mobile_phone ?? "",
    previous_company_name: getSafePreviousCompanyName(person) ?? "",
    twitter_username: person.twitter_username ?? "",
    recommended_personal_email: person.recommended_personal_email ?? "",
    work_email: person.work_email ?? person.emails?.find((e) => e.type === "current_professional")?.address ?? "",
    twitter_url: person.twitter_url ?? "",
    gender: "",
    job_title: person.job_title ?? "",
    industry: person.industry ?? "",
    location_postal_code: person.location_postal_code ?? "",
});
export const generateDateRange = (cadence) => {
    switch (cadence) {
        case "DAILY":
            return "now-1d/d";
        case "WEEKLY":
            return "now-7d/d";
        case "MONTHLY":
            return "now-30d/d";
        case "YEARLY":
            return "now-365d/d";
        default:
            return "now-30d/d";
    }
};
export const mapB2BReactionsToItems = (postUrn, reactions) => {
    return reactions.map((reaction) => ({
        fullName: `${reaction.reactor?.firstName} ${reaction.reactor?.lastName}`,
        headline: reaction.reactor?.headline ?? "",
        reactionType: reaction.reaction,
        urn: postUrn,
        profileUrl: `https://linkedin.com/in/${reaction.reactor?.profileId}`,
        profilePicture: [
            {
                height: 100,
                width: 100,
                url: reaction.reactor?.profilePicture ?? "",
            },
        ],
        profileType: "",
    }));
};
export const mapReactionsToItems = (reactions) => reactions.map((reaction) => ({
    fullName: "",
    headline: "",
    reactionType: reaction.reactionType,
    urn: reaction.actionUrn.split(":")[3],
    profileUrl: reaction.navigationUrl,
    profilePicture: [
        {
            height: 100,
            width: 100,
            url: reaction.image?.profilePic,
        },
    ],
    profileType: "",
}));
export const mapB2BCommentsToComments = (comments) => comments.map((comment) => ({
    commenter: {
        navigationUrl: `https://linkedin.com/in/${comment.commenter?.profileId}`,
        subtitle: comment.commenter?.headline ?? "",
        title: `${comment.commenter?.firstName} ${comment.commenter?.lastName}`,
        image: comment.commenter?.profilePicture ?? "",
    },
    edited: false,
    permalink: comment.url,
    pinned: false,
    reactionTypeCounts: [],
    repliesCount: 0,
    text: comment.comment,
    timestamp: Date.now(),
    topReplies: [],
}));
export const mapLinkedinGetProfilePostCommentsToComments = (comments) => comments?.map((c) => ({
    commenter: {
        navigationUrl: c.author.linkedinUrl,
        subtitle: c.author.title,
        title: `${c.author.firstName} ${c.author.LastName}`,
        image: "",
    },
    edited: c.isEdited,
    permalink: c.permalink,
    pinned: c.isPinned,
    reactionTypeCounts: [],
    repliesCount: 0,
    text: c.text,
    timestamp: c.createdAt,
    topReplies: [],
})) ?? [];
